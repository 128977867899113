<template>
  <b-card>
    <title-name />
    <prefix />
    <suffix />
    <show-list />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Estate_config_features/elements/Title.vue'
import Prefix from '@/views/Admin/Defines/Estate_config_features/elements/Prefix.vue'
import Suffix from '@/views/Admin/Defines/Estate_config_features/elements/Suffix.vue'
import ShowList from '@/views/Admin/Defines/Estate_config_features/elements/ShowList.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleName,
    Prefix,
    Suffix,
    ShowList,
  },
}
</script>
