<template>
  <b-form-group
    label="Ön Eki"
    label-for="prefix"
  >
    <b-form-input
      id="prefix"
      v-model="dataItem.prefix"
      placeholder="Ön Eki"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Prefix',
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['estateConfigFeatures/dataItem']
    },
  },
}
</script>
