<template>
  <b-form-group
    label-for="show_list"
  >
    <b-form-checkbox
      id="show_list"
      v-model="dataItem.show_list"
      value="1"
      switch
    >
      Listeleme Ekranlarında Göster
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'ShowList',
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['estateConfigFeatures/dataItem']
    },
  },
}
</script>
